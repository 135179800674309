<template>
        <div>
            <vue-dropzone
                    ref="dropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    :useCustomSlot=true
      @vdropzone-file-added="vfileAdded"
      @vdropzone-success="vsuccess"
      @vdropzone-error="verror"
            > <slot name="info">
                <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Trascina qui un file per caricarlo</h3>
                    <div class="subtitle">...oppure fai click per caricarne uno dal tuo computer</div>
                    <p class="mt-2" ><b style="color:gray;">Seleziona un file con estensione .pdf</b></p>
                </div>
              </slot>
            </vue-dropzone>
        </div>
</template>

<style>
    .example-drag label.btn {
        margin-bottom: 0;
        margin-right: 1rem;
    }

    .example-drag .drop-active {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: 9999;
        opacity: .6;
        text-align: center;
        background: #000;
    }

    .example-drag .drop-active h3 {
        margin: -.5em 0 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 40px;
        color: #fff;
        padding: 0;
    }
</style>

<script>
   export default {
        name: 'altrama-pdf-upload-component',
        data() {
            return {
               dropzoneOptions: {
                    url: this.$url + "attachments/upload",
                    thumbnailWidth: 150,
                    maxFilesize: 100,
                    headers:  this.$config.axiosHeadersUpload(),
                    addRemoveLinks: true,
                    acceptedFiles:  "application/pdf"
                }
            }
        },
        methods: {
          vfileAdded(file) { },
          vsuccess(file, response) {
              this.$refs["dropzone"].removeFile(file);
              this.$emit( "uploaded", response );
          },
          verror(file){ }
        },
    }
</script>
