<template>
  <div class="form-group">
    <label for="select-license">Versione Licenza</label>

    <select
      id="select-license"
      v-model="options_data_selected"
      name="Versione"
      v-validate="'required'"
      @change="notifySuperComponent()"
     class="custom-select"
    >
      <option v-for="item in options_data" :value="item.id" :key="item.id">{{item.name}}</option>
    </select>

      <div class="small text-danger" v-if="errors.has(('Versione'))"
                    style="height: 1.6rem; margin-bottom:-1.6rem;"
              >{{errors.first('Versione')}}
            </div>

  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {

  components: { Multiselect },

  data() {
    return {
      value: null,
      options_data: [],
      options_data_selected: "",
      myVarTimeout: "", //variabile per interrompere processo di timeout nelle richieste al server per ottenere le potenziali destinazioni, tag, categorie...
      isLoading: false
    };
  },

  props: {
    option_input: {
      type: Array,
      default: () => []
    },
    option_selected_input: {
      type: Number
    },

    type: {
      type: String,
      default: ""
    }
  },

  watch: {
    option_selected_input: function() {
      //alert(this.option_selected_input);
      this.options_data_selected = this.option_selected_input;
      this.notifySuperComponent();
    }
  },

  inject: ["parentValidator"],

  methods: {
    notifySuperComponent: function() {
    //  alert("notifySuperComponent change related authore "+this.options_data_selected);
      this.$emit("updateVersione", this.options_data_selected);
    },





  },

  created() {
    //passo alle variabili data i contenuti ricevuti in input
     this.$validator = this.parentValidator;
     //alert("created");
    this.options_data_selected=this.option_selected_input;

    var self = this;

    axios({
      method: "get",
      url: this.$url + "operations/categories?main=9&lang="+self.$config.getLanguage(),
      headers: this.$config.axiosHeaders()
    })
      .then(function(response) {

        self.options_data = response.data.data;

      })
      .catch(function(error) {


        self.$autorizzazioneValida(error.response);
      });


    this.notifySuperComponent();
  }
}; // export
</script>
