<template>
    <div class="form-group">
        <label for="select-status">Lingua</label>
        <select
                v-model="selected"
                @change="notifySuperComponent()"
                id="select-lang"
                name="Lingua"
                class="custom-select"
        >
            <option value="all" selected="selected">Tutte</option>
            <option
                    v-for="item in options_data"
                    :value="item.code"
                    :key="item.code"
            >{{item.label}}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "LangBox",

        data() {
            return {
                options_data: this.$available_langs
            }
        },
        props: {
            value: {
                type: String,
                default: 'all'
            }
        },
        watch: {
            value: function () {
                this.selected = this.value;
            }
        },
        methods: {
            notifySuperComponent: function () {
                this.$emit("input", this.selected);
            },
        },

        created() {
            this.selected = this.value;
        }
    }; // export
</script>
