<template>
  <div v-if="options != null">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-2 mt-2">
        <label>Ingresso</label>
        <select
          id="id_ingresso_ticket"
          v-model="options.ingresso"
          name="Ingresso"
          v-validate="''"
          class="custom-select"
          @change="notify"
        >
          <option v-for="item in options_data" :value="item.id" :key="item.id" :disabled="item.id==-1">{{item.name}}</option>
        </select>
        <div
          class="small text-danger"
          v-if="errors.has('Ingresso') "
          style="height: 1.6rem; margin-bottom:-1.6rem;"
        >{{errors.first('Ingresso')}}</div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-2 mt-2">
        <label for="Tariffa Intera">Tariffa Intera</label>
        <input
          type="text"
          class="form-control"
          id="Tariffa Intera"
          v-model="options.info[lang].tariffa_intera"
          @change="notify"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-2 mt-2">
        <label for="Tariffa Ridotta">Tariffa Ridotta</label>
        <input
          v-model="options.info[lang].tariffa_ridotta"
          type="text"
          class="form-control"
          id="Tariffa Ridotta"
           @change="notify"
        />
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-2 mt-2">
        <label for="Gratuita">Gratuità</label>
        <input
          v-model="options.info[lang].tariffa_gratuita"
          type="text"
          class="form-control"
          id="Gratuita"
           @change="notify"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-2 mt-2">
        <label for="Abbonamenti">Abbonamenti</label>
        <input
          v-model="options.info[lang].abbonamenti"
          type="text"
          class="form-control"
          id="Abbonamenti"
           @change="notify"
        />
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-2 mt-2">
        <label for="Sito Ufficiale">Sito Ufficiale</label>
        <input
          v-model="options.info[lang].sito"
          name="Sito Ufficiale"
          v-validate="'url'"
          type="url"
          class="form-control"
          id="id_sito_ticket"
           @change="notify"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: null,
      options_data: [
        { id: -1, name: "Seleziona un valore" },
        { id: 1, name: "A Pagamento" },
        { id: 2, name: "Gratuito" },
        { id: 3, name: "Alcuni eventi a pagamento" }
      ]
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    lang: {
      type: String,
      default: () => "it"
    }
  },

  watch: {
    value: function(v2, v1) {
      this.parse(v2);
    }
  },

  methods: {
    parse: function(v2) {
      this.options = Object.assign(
        {},
        {ingresso: -1,
        info: {} },
        v2
      );


this.options.info = Object.assign({}, this.$contentTemplate({
          tariffa_intera: "",
          tariffa_gratuita: "",
          tariffa_ridotta: "",
          abbonamenti: "",
          sito: ""
        }), v2.info );


    },
    notify: function() {
      this.$emit("input", this.options);
    }
  },

  mounted() {
    this.parse(this.value);
  }
}; // export
</script>
