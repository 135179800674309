import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import imageGalleryIcon from './gallery-svgrepo-com.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import ImagePicker from "./ImagePicker.vue";
import Vue from "vue";

export default class MediaGallery extends Plugin {
  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add('mediaGallery', locale => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Aggiungi immagine',
        icon: imageIcon,
        tooltip: true
      });

      // Callback executed once the image is clicked.
      view.on('execute', () => {

        var ComponentClass = Vue.extend(ImagePicker);
        var instance = new ComponentClass({
          propsData: { type: "primary" }
        });
        instance.$mount();
        instance.$on("close", function () {
          instance.$destroy();
          editor.sourceElement.parentNode.removeChild(this.$el);
        });
        instance.$on("onimage", function (images) {
          instance.$destroy();
          editor.sourceElement.parentNode.removeChild(this.$el);

          if (images && images.length > 0) {

            const element = editor.model.change(writer => {
              let imagesHolder = writer.createDocumentFragment();

              images.forEach(function (img) {
                const image = writer.createElement('imageBlock', { src: img.url, alt: img.title });
                const caption = writer.createElement('caption');

                writer.appendText(img.copyright, caption);
                writer.append(caption, image);
                writer.append(image, imagesHolder );
              });
              return imagesHolder;
            });
            editor.model.insertContent(element, editor.model.document.selection);
          }

        });
        editor.sourceElement.parentNode.appendChild(instance.$el);

      });

      return view;
    });
  }
}


