<template>
  <div class="form-group">
    <loader :show="isLoading" :width="40" :height="40"></loader>
    <multiselect
        v-if="!isLoading"
        v-model="selected"
        @input="notifySuperComponent"
        :multiple="false"
        :options="options"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Seleziona un Comune"
        label="city"
        track-by="id"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  components: { Multiselect },
  data() {
    return {
      options: [],
      selected: 0,
      isLoading: true
    };
  },

  props: {
    value: {
      type: Object
    },
  },

  methods: {
    notifySuperComponent: function(value, id) {
      this.$emit("input", this.selected);
    },
    clearAll: function() {
      this.selected = [];
    },
    validateInput: function(input) {
      var self = this;
      if (this.options.length > 0) {

        let index = _.findIndex(self.options, function(o) {
          return o.id == input;
        });
        if (index >= 0) {
          this.$set(
              this,
              "selected",self.options[index]
          );
        } else {
          this.selected = {};
        }
      }
    }
  },

  created() {
    var self = this;
    axios({
      method: "get",
      url: this.$url + "operations/comuni",
      headers: this.$config.axiosHeaders()
    })
        .then(function(response) {
          self.options = response.data.data;
          self.isLoading = false;
        })
        .catch(function(error) {
          self.isLoading = false;
          self.$processError(error);
        });
  }
}; // export
</script>
