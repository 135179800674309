<template>
    <div>
        <loader :show="isLoading" :width="40" :height="40"></loader>
        <div v-for="(item, index) in options" :key="item.name" class="custom-control custom-checkbox form-check">
            <input
                    type="checkbox"
                    class="custom-control-input form-check-input"
                    :id="item.id"
                    :value="item"
                    v-model="categories[index].active"
                    @change="notify"
                    :disabled="!item.enabled"
            />

            <label class="custom-control-label form-check-label" :for="item.id">{{ item.name }}</label>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                categories: [],
                options: [],
                isLoading: true
            };
        },

        props: {
            value: {
                type: Array,
                default: () => []
            }
        },

        watch: {
            value: function(v1, v2) {
                if (v1 == v2) return;
                this.$set(this, "categories", this.value);
            }
        },

        methods: {
            notify: function() {
                this.$emit("input", this.categories);
                this.$emit("change", this.categories);
            },
        },

         async mounted() {
            var self = this;
            self.isLoading = true;

            let roles = (await this.$api.user.current()).roles;
            var enabled_ert = roles.hasOwnProperty('redazione-regionale') ||  roles.hasOwnProperty('administrator') ;
            var enabled_emilia = true; // (this.$branding.portal == 'emilia' && roles.hasOwnProperty('redazione-destinazione-turistica')) ||  enabled_ert;
            var enabled_romagna = true; //(this.$branding.portal == 'romagna' && roles.hasOwnProperty('redazione-destinazione-turistica')) ||  enabled_ert;

            var obj = [
                {
                    'id':1,
                    'name': 'Emilia-Romagna',
                     enabled: enabled_ert
                },
                {
                    'id':2,
                    'name': 'Emilia',
                    enabled: enabled_emilia
                },
                {
                    'id':3,
                    'name': 'Romagna',
                    enabled: enabled_romagna
                },
            ];
            self.$set(self, "options", obj);
            self.$set(self, "categories", this.value);
            self.isLoading = false;
        }
    }; // export
</script>
