<template>
  <div class="form-group">
    <label v-if="title"> {{title}}</label>
    <textarea
                :rows="rows"
                :cols="cols"
                :maxlength="maxLength"
                :value="value"
            :required="required"
            :class="['input', 'form-control', !!v && v.$error ? 'is-invalid': '' ,  ...classes ]"
            v-on:input="$emit('input', $event.target.value.trim() )"
    />
     <div class="invalid-feedback" v-if="!!v && !v.required">Campo richiesto</div>
              <span
                class="pull-right d-inline-block small"
              >{{ maxLength - value.length }} caratteri disponibili.</span>
  </div>
</template>
<script>
export default {
  props: {
      value : { type: String, default : '' },
      title : { type:String, default : null },
      rows: { type:Number, default : 6 },
      cols: { type:Number, default : 50 },
      maxLength: { type:Number, default : 300 },
      required: { type: Boolean, default: false },
      classes: {type: Array, default: () => [] },
      typeName: {type: String, default:'text' },
      v: { type: Object, default: null },
  }
};
</script>
