<template>
  <div class="form-group">
    <label for="select-status">Stato</label>
    <loader :show="isLoading" :width="40" :height="40"></loader>
    <select
      v-if="!isLoading"
      v-model="selected"
      @change="notifySuperComponent()"
      id="select-status"
      name="Stato"
      class="custom-select"
    >
      <option v-if="title == true" value="-1">Tutti</option>
      <option
        v-for="item in options_data"
        :value="item.id"
        :disabled="!item.active"
        :key="item.id"
      >
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StatusesBox",

  data() {
    return {
      options_data: [],
      options_data_lang: null,
      selected: 2,
      isLoading: true,
    };
  },
  props: {
    value: {
      type: [Number, String],
      default: -1,
    },
    title: {
      type: [Boolean, String],
      default: true,
    },
    list: {
      type: [Boolean, String],
      default: false,
    },
    name: {
      type: [String],
      default: "assente",
    },
    statuses: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: [Number, String],
      default: () => 0,
    },
    lang: {
      type: String,
      default: () => "it",
    },
  },
  watch: {
    value: function () {
      this.selected = this.value;
    },
    statuses: function () {
      this.applyStatusesProp();
    },
    entity: function () {
      this.asyncRelatedStatuses();
    },
    lang: function () {
      this.changeLang();
    },
  },
  methods: {
    notifySuperComponent: function () {
      this.$emit("input", this.selected);
    },
    clearAll: function () {
      this.selected = [];
    },
    applyStatusesProp: function () {
      if (this.statuses && Object.keys(this.statuses).length > 0) {
        this.options_data = [];
        for (const [key, value] of Object.entries(this.statuses)) {
          this.options_data.push({
            active: value > 0,
            name: key,
            id: Math.abs(value),
          });
        }
      }
    },
    update: function(){
       this.asyncRelatedStatuses();
    },
    changeLang: function () {
      if (this.options_data_lang) {
        this.options_data = this.options_data_lang[this.lang];
      } else {
        this.asyncRelatedStatuses();
      }
    },
    async asyncRelatedStatuses() {
      let self = this;
      self.isLoading = true;

      await axios({
        method: "GET",
        url: self.$url + "operations/related_statuses",
        params: {
          list: self.list,
          name: self.name,
          id: self.entity || "new",
        },
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          if (!self.entity) {
            self.options_data = [...response.data.data];
          } else {
            self.options_data = [...response.data[self.lang]];
            self.options_data_lang = response.data;
          }
        })
        .catch(function (error) {
          self.$processError(error);

        })
        .finally(() => (self.isLoading = false));
    },
    onValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          //   bus.$emit('no-errors-validate-child',result);
        }
      });
    },
  },

  created() {
    this.selected = this.value;
    if (this.statuses && Object.keys(this.statuses).length > 0) {
      this.applyStatusesProp();
      this.isLoading = false;
    } else {
      this.asyncRelatedStatuses();
    }
  },
}; // export
</script>
