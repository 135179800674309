import Vue from 'vue';

const pickImage = function (node, max) {
    return new Promise((resolve) => {
        let ImagePicker = require("./ImagePicker.vue").default;
        var ComponentClass = Vue.extend(ImagePicker);
        var instance = new ComponentClass({
            propsData: { type: "primary", max: max || 100 }
        });
        instance.$mount();
        instance.$on("close", function () {            
           node.parentNode.removeChild(instance.$el);
           instance.$destroy();
        });
        instance.$on("onimage", function (images) {
            resolve(images);            
            node.parentNode.removeChild(instance.$el);
            instance.$destroy();
        });
        node.parentNode.appendChild(instance.$el);
    });
}



export default pickImage;