<template>
    <div class="form-group">
        <label for="select-status">Provincia</label>
        <loader :show="isLoading" :width="40" :height="40"></loader>
        <select
                v-if="!isLoading"
                v-model="selected"
                @change="notifySuperComponent()"
                id="select-status"
                name="Province"
                class="custom-select" >
            <option
                    v-for="item in options_data"
                    :value="item.id"
                    :key="item.id"
            >{{item.nome}}
            </option>
        </select>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ProvinceCombo",

        data() {
            return {
                options_data: [],
                selected: -1,
                isLoading: true
            };
        },

        props: {
            value: {
                type: Number,
                default: -1
            }
        },

        watch: {
            value: function () {
                this.selected = this.value;
            }
        },

        methods: {
            notifySuperComponent: function () {
                this.$emit("input", this.selected);
            },
            clearAll: function () {
                this.selected = [];
            },
            async asyncRelatedStatuses() {
                var self = this;
                self.isLoading = true;

                await axios({
                    method: "GET",
                    url: self.$url + "operations/province",
                    headers: this.$config.axiosHeaders()
                })
                    .then(function (response) {
                        self.options_data = [
                            {
                                id: -1,
                                nome: "Tutte"
                            },
                            ...response.data.data
                        ];
                    })
                    .catch(function (error) {
                        self.$processError(error);
                        
                    })
                    .finally(
                        () => self.isLoading = false
                    );
            },
        },

        created() {
            this.selected = this.value;
            this.asyncRelatedStatuses();
        }
    };
</script>
