<template>
  <div class="form-group">
    <label for="select-searchbox">Parola Chiave</label>
    <input
      @change="notifySuperComponent()"
      placeholder=""
      value=""
      v-model="selected"
      class="input form-control"
      type="search"
    >
  </div>
</template>

<script>
  export default {
    name: "SearchBox",

    data() {
      return {
        selected: '',
      };
    },
    props: {
      value: {
        type: String,
        default: ''
      }
    },
    watch: {
      value: function () {
        this.selected = this.value;
      }
    },
    methods: {
      notifySuperComponent: function () {
        this.$emit("input", this.selected);
        this.$emit("change", this.selected);
      },
    },

    created() {
      this.selected = this.value;
    },
  }; // export
</script>
