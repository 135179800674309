import FormGroupInput from "./Inputs/formGroupInput.vue";

import DropDown from "./Inputs/Dropdown.vue";
import Button from "./Inputs/Button";
import Card from "./Cards/Card.vue";
import ChartCard from "./Cards/ChartCard.vue";
import StatsCard from "./Cards/StatsCard.vue";

import ActionListTableComponent from "./Inputs/actionListTableComponent.vue";
import DraggableComponent from "./Inputs/draggableComponent.vue";


import RelatedCategoryMedia from "./altrama/RelatedCategoryMedia.vue";
import RelatedCategoryVersioneMedia from "./altrama/RelatedCategoryVersioneMedia.vue";
import RelatedLanguages from "./altrama/RelatedLanguages.vue";

let components = {
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  DropDown,
  Button,
  ActionListTableComponent,
  DraggableComponent,
  RelatedCategoryMedia,
  RelatedCategoryVersioneMedia,
  RelatedLanguages
};

export default components;

export {
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  DropDown,
  Button,
  ActionListTableComponent,
  DraggableComponent,
  RelatedCategoryMedia,
  RelatedCategoryVersioneMedia,
  RelatedLanguages
};
