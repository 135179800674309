<template>
  <div>
    <draggable
      v-if="tappe.length > 0"
      tag="div"
      :list="tappe"
      class="accordion"
      handle=".handle"
      id="tappeAccordion"
      @end="onMoveCallback"
    >
      <div class="card" v-for="(element, idx) in tappe" :key="element.idComponente">
        <div class="card-header" id="headingOne">
          <div class="row align-items-center">
            <div class="col-auto"><i class="fa fa-align-justify handle" style="cursor: move;"></i></div>
            <div class="col mx-auto">{{ idx + 1 }}. {{ (element.info[lang] ? element.info[lang] + ", " : 'Luogo,' ) }}  {{ element.location.address ? element.location.address  + ", " : '' }} {{element.location.city.city }}</div>
            <div class="col-auto"><button type="button" class="btn btn-lg btn-link mx-2" data-toggle="collapse" :data-target="'#collapse-tappa' + idx" aria-expanded="true" aria-controls="collapseOne" ><i class="fa fa-edit"></i></button></div>
            <div class="col-auto"><button type="button" class="btn btn-lg btn-link trash" @click="removeAt(idx)"><i class="fa fa-trash"></i></button></div>
          </div>
        </div>
        <div
          :id="'collapse-tappa' + idx"
          class="collapse collapsed"
          :aria-labelledby="'Tappa n°' + (idx + 1)"
          data-parent="#tappeAccordion"
        >
          <div class="card-body">
            <div class="form-row">
            <div class="form-group col-md-6">
              <label>Info extra (dettagli luogo o titolo evento)</label>
              <input
                type="text"
                required="true"
                class="input form-control"
                aria-required="true"
                aria-invalid="false"
                v-model="element.info[lang]"
              />
            </div>

             <div class="form-group col-md-6">
              <label>Destinazioni</label>
              <destination-combo
                required="true"
                aria-required="true"
                aria-invalid="false"
                v-model="element.destination"
              />
            </div>

            </div>

            <address-box v-model="element.location" > </address-box>
            <!-- component v-bind:is="element.template" v-model="tappe[idx]" :lang="lang"></!-->
          </div>
        </div>
      </div>
    </draggable>
    <div class="col-12 text-center">
        <button type="button" class="btn btn-primary" @click="addTappa()"> Aggiungi luogo </button>
    </div>
  </div>
</template>
<style scoped>
.list-group-item {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
<script>
import draggable from "vuedraggable";

import DestinationCombo from "./DestinationCombo"
import AddressBox from "./AddressBox"
export default {
  name: "TappeBox",
  components: {
    draggable,
    AddressBox,
    DestinationCombo
  },
  methods: {
    addTappa: function(type) {
      this.tappe.push({
        id: null,
        order: this.tappe.length + 1,
        info: this.$contentTemplate(),
        destination: {},
        location: {
                  city: {
                    id: "",
                    city: "",
                    province: "",
                    province_short: "",
                    region: "",
                    zip_code: "",
                    country: ""
                  },
                  address: "",
                  number: "",
                  lat: "",
                  lng: ""
                },
      });
    },
    removeAt: function(idx) {
      if (confirm("Confermi la rimozione dell'elemento?")) {
        this.tappe.splice(idx, 1);
      }
    },
    onMoveCallback: function(evt, originalEvent) {
      for (var i in this.tappe) {
        this.tappe[i].order = Number(i) + 1;
      }
    },
    updateTappe: function() {
        this.$set(this, "tappe", this.value || []);
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    lang: {
      type: String,
      default: "it"
    }
  },
  watch: {
    lang(v2, v1) {
      this.tappe.forEach(element => {
        this.$set(element.info, v2, element.info[v2] || "" );
      });
    },
    value: {
                handler(val, oldVal) {

                    this.updateTappe();
                },
                deep: true
            }
  },
  mounted() {
    this.updateTappe();
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      tappe: []
    };
  }
};
</script>

