<template>
        <select
                v-model="selected"
                @change="notifySuperComponent()"
                id="select-language"
                name="Lingua"
                class="custom-select"
        >
            <option
                    v-for="item in options_data"
                    :value="item.code"
                    :key="item.code"
            >{{item.label}}
            </option>
        </select>
</template>

<script>
    export default {
        data() {
            return {
                options_data: [],
                selected: "",
            };
        },
        props: {
            value: {
                type: String,
                default: "all"
            }
        },
        watch: {
            value: function () {
                this.selected = this.value;
            }
        },
        methods: {
            notifySuperComponent: function () {
                this.$emit("input", this.selected);
            },

            async asyncRelatedStatuses() {
                let self = this;
                self.options_data = [
                    {
                        code: "all",
                        label: "Tutte"
                    },
                    ...this.$available_langs

                ];
            },
        },

        created() {
            this.selected = this.value;
            this.asyncRelatedStatuses();
        }
    };
</script>
