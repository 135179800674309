<template>
  <div class="form-group">
    <label v-if="title"> {{title}}</label>
    <input
            type="text"
            :required="required"
            :class="['input', 'form-control', !!v && v.$error ? 'is-invalid': '' ,  ...classes ]"
            :value="value"
            :maxlength="maxLength"
            v-on:input="$emit('input', $event.target.value.trim() )"
            @blur="$emit('blur')"
    />
    <span v-if="maxLength>0 && maxLength < 524288" class="pull-right d-inline-block small">{{ maxLength - value.length }} caratteri disponibili.</span>
    <div class="invalid-feedback" v-if="!!v && (v.required==false)">Campo richiesto</div>
    <div class="invalid-feedback" v-if="!!v && (v.url==false)">URL non valida</div>
  </div>
</template>
<script>
export default {
  props: {
      value : { type: String, default : '' },
      title : { type:String, default : null },
      required: { type: Boolean, default: false },
      v: { type: Object, default: null },
      classes: {type: Array, default: () => [] },
      typeName: {type: String, default:'text' },
      maxLength: { type:Number, default : 524288 },
  }
};
</script>
