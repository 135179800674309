<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Seleziona Allegato
              <small
                style="color:gray;"
                v-if="max<100"
              >(Si possono allegare massimo {{max}} file)</small>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="$emit('close')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-show="!showUploader" name="Galleria" id="galleria">
              <div v-if="filterObj==null" class="row justify-content-md-center search my-2">
                <div class="col-md-5">
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="p_search"
                      class="form-control"
                      placeholder="Filtra"
                      aria-label="Filtro"
                    />

                    <div class="input-group-append">
                      <button
                        class="btn btn-info"
                        type="button"
                        :disabled="loading"
                        @click="asyncFindString(p_search)"
                      >Filtra</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="input-group">
                    <button
                      class="btn btn-info"
                      type="button"
                      alt="Aggiorna"
                      :disabled="loading"
                      @click="reloadImages()"
                    >
                      <i class="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                    <button
                      class="btn btn-info"
                      type="button"
                      :disabled="loading"
                      @click="showUploader=true"
                    >Carica Immagini</button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="content_gallery col pt-2">
                  <mapspartout-gallery
                    :images="images"
                    :focus="filterObj ? filterObj.id : 0"
                    v-on:changeMedia="updateMedia($event)"
                    v-on:selectedAllegati="updateMediaSelected($event)"
                  />
                  <div class="col-12 text-center mb-3">
                    <loader :show="loading" :width="40" :height="40"></loader>
                    <button
                      v-if="pagination.current_page < pagination.last_page && !loading"
                      class="btn btn-default"
                      @click="getMedia()"
                    >Mostra altro</button>
                  </div>
                </div>
                <div class="col-md-4 info_box_media" v-if="index_media_gallery!=-1">
                    <form>
              <div class="col-md-12 text-center">
                <img
                  v-bind:src="images[index_media_gallery].url"
                  alt
                  class="d-inline-block"
                  style="width: 180px"
                />
              </div>
              <div class="col-md-12 text-center">
                 <a style="color:black" target="_blank" :href="images[index_media_gallery].url" :title="images[index_media_gallery].name">{{images[index_media_gallery].name }}</a>
                </div>
              <hr />
              <div class="form-group">
                <label>Titolo</label>
                <input
                  type="text"
                  :class="{ 'input form-control': true }"
                  placeholder
                  v-model="images[index_media_gallery].title"
                />
              </div>

              <div class="form-group">
                <label>Descrizione</label>
                <textarea
                  rows="4"
                  cols="50"
                  class="input form-control"
                  v-model="images[index_media_gallery].description"
                ></textarea>
              </div>

              <div class="form-group">
                <label>Copyright</label>
                <input
                  type="text"
                  :class="{ 'input form-control': true }"
                  placeholder
                  v-model="images[index_media_gallery].copyright"
                />
              </div>
              <div class="form-group">
                <related-category-media
                  type="attachment"
                  :option_selected_input="
                    images[index_media_gallery].license_id
                  "
                  v-on:updateLicense="updateLicense($event)"
                ></related-category-media>
              </div>
              <hr />
              <div class="form-group">
                <input
                  type="checkbox"
                  class="input mr-4"
                  v-model="images[index_media_gallery].openlibrary"
                />
                <label>Pubblica in Opendata</label><br>
<small>se si spunta l'immagine sarà pubblicata negli opendata</small>
              </div>
              <div class="col-md-12 col-lg-12 text-center">

                <hr />
                <span
                  class="btn btn-danger btn-sm"
                  @click="
                    cancellaImmagineDaGallery(images[index_media_gallery].id)
                  "
                >
                  <span> <i class="fa fa-trash"></i> Elimina </span>
                </span>
                <span
                  class="btn btn-primary btn-sm"
                  @click="
                    modificaImmagineDaGallery(images[index_media_gallery].id)
                  "
                >
                  <span> <i class="fa fa-check"></i> Salva </span>
                </span>

              </div>
              <hr />
            </form>
                </div>
              </div>
            </div>
            <div v-show="showUploader" name="Nuovo File" id="new-file">
              <div class="row">
                <div class="col">
                  <altrama-upload-component
                    v-on:new-upload="updateUpload"
                    v-on:add-list-attachment="completedUpload"
                    :minImageWidth="300"
                    :minImageHeight="300"
                    :minSize="300"
                    collectionType="images"
                  ></altrama-upload-component>
                </div>
              </div>

              <div class="row">
                <div class="col-auto ml-auto my-4">
                  <button
                    class="btn btn-info"
                    type="button"
                    @click="showUploader=false; reloadImages();"
                  >Torna alla gallery</button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="isGallery">
            <input type="button" class="btn btn-secondary" value="CHIUDI" @click="$emit('close')" />
            <input
              v-if="imagesSelected.length<=max"
              v-show=" imagesSelected.length > 0 "
              type="button"
              :class="['btn', imagesSelected.length > 0 ? 'btn-primary' : 'btn-primary-outline' ]"
              value="INSERISCI"
              @click="$emit('onimage', imagesSelected )"
              :disabled="imagesSelected.length==0"
            />
            <input
              v-else
              type="button"
              class="btn btn-danger"
              value="TROPPI ALLEGATI"
              @click="$emit('onimage', imagesSelected )"
              disabled
            />
          </div>

          <div class="modal-footer" v-else>
            <input type="button" class="btn btn-secondary" value="CHIUDI" @click="$emit('close')" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.image-label {
  display: block;
  max-width: 150px;
}
.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 120px;
  width: 150px;
  margin-bottom: 10px;
  cursor: pointer;

  &.selected {
    box-shadow: 0 0 0 4px #b9e00b;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
}

.modal-header,
.modal-footer {
  padding: 1rem;
}

.modal-body {
  margin: 0 15px;
  padding: 0;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.button-new {
  &:active {
    border-style: solid;
    i {
      text-shadow: 1px 1px 1px darkgrey;
    }
  }

  background-color: #dee2e6;
  color: #66615b;
  width: 150px;
  height: 150px;

  i {
    font-size: 4rem;
    color: #ffffff9e;
    display: block;
    margin-bottom: 11px;
    text-shadow: 2px 2px 1px darkgrey;
  }
}

.content_gallery,
.info_box_media {
  overflow-y: scroll;
  max-height: calc(100vh - 250px);
}

.info_box_media {
  border-left: 1px #d8d8d8 dashed;
  transition: 200ms linear;
}
</style>
<script>
import axios from "axios";
import _ from "lodash";
const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
import AltramaUploadComponent from "../../Inputs/customComponentUpload"
import MapspartoutGallery from "../../Inputs/mapspartout-custom-gallery"

export default {
  components: { AltramaUploadComponent, MapspartoutGallery },
  name: "ImagePicker",
  data() {
    return {
      showUploader: false,
      options_data: [],
      options_data_selected: [],
      myVarTimeout: -1, //variabile per interrompere processo di timeout nelle richieste al server per ottenere le potenziali destinazioni, tag, categorie...
      loading: true,
      isGallery: true,
      defaultTabHash: "gallery",
      indexPlanning: Math.floor(Math.random() * 10000),
      type: "imageBox",
      //variabili per gestire le immagini
      infoTab: null,
      index_media_gallery: -1, //indica il media che si sta lavorando adesso
      image_preview_gallery: "", //image preview in gallery, riferito al campo precedente
      image_preview_url: "", //
      image_preview_title: "", //
      image_preview_copyright: "", //
      image_preview_description: "", //
      images: [],
      imagesSelected: [], //immagini selezionati temporaneamente dalla gallery e rese "ufficiali" soltanto quando si preme il pulsante inserisci
      //serve per stabilire se è necessario recuperare i media dal server nuovamente a causa di nuovi item presenti. Si controlla quando si attiva il tab-gallery
      nuovoUpload: false,
      id_predefinito: "",
      focus_id: -1,
      p_search: "",
      selectedLicense: 0,
      selectedVersione: 0,
      currentLengthNewUpload: 0,
      pagination: {
        total: 0,
        per_page: 30,
        current_page: 1,
        last_page: 0,
        from: 1,
        to: 20
      },
      paginationOptions: {
        offset: 4,
        alwaysShowPrevNext: true
      }
    };
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    filterObj: {
      type: Object,
      default: null
    },

    max: {
      type: Number,
      required: false,
      default: 100
    }
  },

  computed: {},

  provide() {
    return { parentValidator: this.$validator };
  },

  methods: {
    resetSearch: function(text) {
      if (text.trim().length == 0) {
        this.p_search = "";
        this.images = [];
        this.imagesSelected = [];
        this.pagination.current_page = 1;
        this.index_media_gallery = -1;
        this.getMedia();
      }
    },
    reloadImages: function(text) {
      this.images = [];
      this.imagesSelected = [];
      this.pagination.current_page = 1;
      this.index_media_gallery = -1;
      this.getMedia();
    },

    updateLicense: function(idLicense) {
      this.selectedLicense = idLicense;
      this.images[this.index_media_gallery].license_id = idLicense;
    },

    enableAdd() {
      if (this.options_data_selected.length)
        return this.imagesSelected.lenght < parseInt(this.max);
      return true;
    },

    getNameModal() {
      return (
        "manage-image-attachemnts" + "_" + this.type + "_" + this.indexPlanning
      );
    },

    selezionaPredefinito(image) {
      for (var index in this.options_data_selected) {
        this.options_data_selected[index].is_primary =
          image.id == this.options_data_selected[index].id;
      }
      this.notifySuperComponent();
    },

    chiudiDialog() {
      this.$modal.hide(
        "manage-image-attachemnts" + "_" + this.type + "_" + this.indexPlanning
      );
      this.index_media_gallery = -1;
    },

    notifySuperComponent: function() {
      this.$emit("input", this.options_data_selected);
    },
    updateUpload: function() {
      //il primo componente della lista sarà allegato direttamente
      this.nuovoUpload = true;
      this.currentLengthNewUpload = this.currentLengthNewUpload + 1;
      //console.log(this.currentLengthNewUpload);
    },

    completedUpload: function() {
      //caricate le immagini, si inseriscono direttamente
      var self = this;
      //rifaccio la chiamata al server e gestisco tutto
      axios({
        method: "get",
        url: self.$url + "media",
        headers: this.$config.axiosHeaders()
      })
        .then(function(response) {
          var start = self.currentLengthNewUpload; //self.images.length;//attenzione, rischia di essere 0 e di conseguenza carca tutte le immagini della gallery
          var obj = response.data.data;
          self.images = Array.from(obj);
          var end = 0;
          end = self.images.length;
          var diff = self.currentLengthNewUpload; //end-start;

          for (var i = 0; i < diff; i++) {
            var obj = self.images[i];
            obj.button_class = "checked";
            obj.class = "";
            self.options_data_selected.push(obj);
          }
          if (
            self.options_data_selected.length == 1 ||
            self.id_predefinito == ""
          ) {
            self.id_predefinito = self.options_data_selected[0].id;
          }

          self.currentLengthNewUpload = 0; //azzero conteggio upload
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    show_dialog_image: function() {
      this.$parent.show_dialog_image(); //old
    },

    show_dialog_image_in_component: function() {
      this.$modal.show(
        "manage-image-attachemnts" + "_" + this.type + "_" + this.indexPlanning
      );
    },

    cancellaAllegato: function(index) {
      var index_R = -1;
      for (var i = 0; i < this.options_data_selected.length; i++) {
        if (this.options_data_selected[i].id == index) {
          index_R = i;
          break;
        }
      }

      for (var i = 0; i < this.images.length; i++) {
        if (this.images[i].id == index) {
          this.images[i].button_class = "check";
          break;
        }
      }

      this.options_data_selected.splice(index_R, 1);
      //gestione id predefinito
      //controllo se dopo la cancellazione l'allegato appena cancellato era il predefinito
      if (
        this.options_data_selected.length > 0 &&
        this.id_predefinito == index
      ) {
        this.id_predefinito = this.options_data_selected[0].id;
      }

      //se dopo la cancellazione non ci sono allegati non ci deve essere predefinito
      if (this.options_data_selected.length == 0) this.id_predefinito = "";
      this.notifySuperComponent();
    },

    modificaAllegato: function(index, data) {
      var index_R = -1;
      for (var i = 0; i < this.options_data_selected.length; i++) {
        if (this.options_data_selected[i].id == index) {
          index_R = i;
          break;
        }
      }
      if (index_R > -1) this.options_data_selected[index_R].title = data.titolo;
    },

    setFocus: function(id) {
      this.show_dialog_image_in_component();
      this.focus_id = id;
    },

    //metodi per modal
    inserisciMediaAllegato: function() {
      if (
        this.imagesSelected.length + this.options_data_selected.length >
        this.max
      ) {
        this.$notifyVue(
          "top",
          "center",
          "warning",
          "Si possono allegare massimo " + this.max + " file",
          "ti-close"
        );
        this.$modal.hide(
          "manage-image-attachemnts" +
            "_" +
            this.type +
            "_" +
            this.indexPlanning
        );
        return;
      }

      if (this.options_data_selected.length == 0) {
        this.options_data_selected = Array.from(this.imagesSelected);
      } else {
        for (var i = 0; i < this.imagesSelected.length; i++) {
          var obj = this.imagesSelected[i];
          //alert(obj.url);
          var found = false;
          for (var j = 0; j < this.options_data_selected.length; j++) {
            if (obj.url == this.options_data_selected[j].url) {
              found = true;
              break;
            }
          }

          if (found == false) {
            this.options_data_selected.push(JSON.parse(JSON.stringify(obj)));
          }
        }
      }
      if (this.options_data_selected.length == 1 || this.id_predefinito == "") {
        this.id_predefinito = this.options_data_selected[0].id;
      }

      //verificare se l'attuale id predefinito è in elenco oppure è stato cancellato in precedenza
      var found_predefinito = false;
      for (var j = 0; j < this.options_data_selected.length; j++) {
        var obj = this.options_data_selected[j];
        if (obj.id == this.id_predefinito) {
          found_predefinito = true;
          break;
        }
      }

      if (!this.found_predefinito && this.options_data_selected.length > 0) {
        this.id_predefinito = this.options_data_selected[0].id;
      }

      this.notifySuperComponent();
      this.$modal.hide(
        "manage-image-attachemnts" + "_" + this.type + "_" + this.indexPlanning
      );
    },

    cancellaImmagineDaGallery(index) {
      if (
        confirm(
          "Confermare cancellazione elemento selezionato? Attenzione, il file sarà rimosso dal server!!!"
        )
      ) {
        var self = this;
        var cancellazione = true;
        axios({
          method: "delete",
          url: self.$url + "media/upload/" + index,
          headers: self.$config.axiosHeaders(),
          params: {
            id: index
          }
        })
          .then(function(response) {
            // chiamata ok, ma può restituire diversi errori
            var color = 2;
            var icon = "ti-check";

            if (response.data.status == "success") {
              self.$notifyVue(
                "top",
                "center",
                response.data.status,
                response.data.data.message,
                "ti-check"
              );
              self.cancellaAllegato(index);
              self.image_preview_gallery = "";
              self.index_media_gallery = -1;
              self.getMedia();
            }
          })
          .catch(function(error) {
            self.$notifyVue(
              "top",
              "center",
              "warning",
              "Errore Cancellazione",
              "ti-close"
            );
            self.$processError(error);
          });
      } // confirm
    },

    modificaImmagineDaGallery(index) {
      var image_data = {
        titolo: this.images[this.index_media_gallery].title,
        description: this.images[this.index_media_gallery].description,
        copyright: this.images[this.index_media_gallery].copyright,
        license_id: this.selectedLicense, //this.images[this.index_media_gallery].license_id
      };
      //chiamata al server
      this.updateMediaFromGallery(index, image_data);
    },

    updateMediaFromGallery(index, data) {
      var self = this;
      axios({
        method: "put",
        url: this.$url + "media/upload/" + index,
        headers: this.$config.axiosHeaders(),
        data: data
      })
        .then(function(response) {
          self.$notifyVue(
            "top",
            "center",
            response.data.status,
            response.data.data.message,
            "ti-check"
          );
          self.modificaAllegato(index, data); //se il media è gia allegato ne modifico che info visibili in tabella
        })
        .catch(function(error) {
          self.$processError(error);
          for (var key in error.response.data.errors) {
            if (key != "debug")
              self.$notifyVue(
                "top",
                "center",
                "warning",
                error.response.data.errors[key],
                "ti-close"
              );
          }
        });
    },

    tabChange(selectedTab) {
      if (selectedTab.tab.id == "gallery" && this.nuovoUpload) {
        this.getMedia();
        this.nuovoUpload = false;
      }

      if (selectedTab.tab.id == "gallery") this.isGallery = true;
      else this.isGallery = false;
    },

    getMedia() {
      var self = this;

      self.loading = true;
      let params = {
        limit: self.pagination.per_page,
        page: self.pagination.current_page,
        query: self.p_search
      };

      if (self.filterObj !== null) {
        params = {
          id: self.filterObj.id
        };
      }

      axios({
        method: "post",
        url: self.$url + "media/search",
        headers: this.$config.axiosHeaders(),
        params: params
      })
        .then(function(response) {
          var obj = response.data.data;
          self.loading = false;
          if (self.pagination.current_page == 1) {
            self.images = [];
            self.imagesSelected = [];
          }
          self.pagination.current_page++;
          self.pagination.last_page = response.data.meta.last_page;

          for (var i = 0; i < obj.length; i++) {
            self.images.push(obj[i]);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    updateMediaSelected: function(item) {
      //item rappresenta  array che cambia ognoqualvolta si spunta un checkbox nella gallery
      this.imagesSelected = item;
    },

    //event trigger from subcomponent gallery
    updateMedia: function(item) {
      if (!item) {
        this.index_media_gallery = -1;
        return;
      }

      this.image_preview_gallery = item.url;
      this.image_preview_url = item.url;
      this.image_preview_title = item.title;
      this.image_preview_copyright = item.copyright;
      this.image_preview_description = item.description;

      let new_index = -1;
      for (var i = 0; i < this.images.length; i++) {
        if (this.images[i].id == item.id) {
          new_index = i;
          break;
        }
      }

      if (new_index == this.index_media_gallery) {
        this.index_media_gallery = -1;
      } else {
        this.index_media_gallery = new_index;
      }
      //aggiorno la licenza nel componente con il media selezionato
      this.updateLicense(item.license_id);
    },

    asyncFindString: function() {
      let self = this;
      self.pagination.current_page = 1;
      self.index_media_gallery = -1;
      self.images = [];
      self.imagesSelected = [];
      self.getMedia();
    },

    search(p_search) {
      var self = this;
      self.emptystate_msg = "";
      self.loading = true;
      if (self.p_search.length > 0) {
        axios({
          method: "post",
          url: self.$url + "media/search",
          headers: this.$config.axiosHeaders(),
          params: {
            keywords: p_search
          }
        })
          .then(function(response) {
            if (response.data.status == "success") {
              self.index_media_gallery = -1;
              self.imagesSelected = [];
              self.images = Array.from(response.data.data.images);
              self.loading = false;
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        self.getMedia();
      }
    }
  },

  watch: {
    value: function() {
      this.options_data_selected = this.value;
      for (var i = 0; i < this.options_data_selected.length; i++)
        if (this.options_data_selected[i].is_primary == 1) {
          this.id_predefinito = this.options_data_selected[i].id;
          break;
        }
      this.notifySuperComponent();
    }
  },

  created() {
    this.options_data_selected = this.value;
    for (var i = 0; i < this.options_data_selected.length; i++)
      if (this.options_data_selected[i].is_primary == 1) {
        this.id_predefinito = this.options_data_selected[i].id;
        break;
      }
    this.getMedia();
    this.notifySuperComponent();
    let el = document.querySelector("#modal-body");
    bodyScrollLock.disableBodyScroll(el);
  },
  destroyed: function() {
    bodyScrollLock.clearAllBodyScrollLocks();
  }
};
</script>
