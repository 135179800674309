<template>
  <multiselect
      v-model="tags"
      tag-placeholder="Aggiungilo come tag"
      placeholder="Cerca e aggiungi un tag"
      :options="options"
      :multiple="true"
      :taggable="canAddTags"
      :loading="isLoading"
      :searchble="true"
      :hide-selected="true"
      @search-change="asyncFind"
      @tag="addTag"
  >
      <span slot="noOption"> Non ci sono tag disponibili </span>
    <span slot="noResults"> Non ci sono tag disponibili </span>
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  name: "TagManager",
  components: {Multiselect},

  data() {
    return {
      tags: [],
      options: [],
      isLoading: false
    };
  },

  props: ["value", "canAddTags", "language"],

  methods: {
    asyncFind: function (itemToSearch) {
      var self = this;
      self.isLoading = true;
      var url =
          this.$url + "operations/tagsBox?lang="
          + this.language
          + "&s=" + itemToSearch;
      axios({
        method: "get",
        url: url,
        headers: this.$config.axiosHeaders()
      })
          .then(function (response) {
            var obj = response.data.data;
            self.options = obj;
            self.isLoading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    addTag(newTag) {
      if (this.canAddTags) {
        const tag = newTag.slugify();
        this.options.push(tag);
        this.tags.push(tag);
      }
    }
  },

  watch: {
    tags: function (v2, v1) {
      this.$emit("input", this.tags);
    },
    value: function (v2, v1) {
      this.tags = this.value;
    }

  },

  created() {
    this.tags = this.value;
    this.asyncFind("");
  }
}; // export
</script>
