<template>
<div v-if="show" :class="[ !inline ? 'w-100 text-center' : 'd-inline' ]" >
      <svg  :width="width" :height="height" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#7A9E9F">
    <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".3" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"/>
            </path>
        </g>
    </g>
</svg>
</div>
</template>
<style scoped>
</style>
<script>
export default {
  name: 'loader',
  props: {
    show:{
      type: Boolean,
      default: true
    },
    width:{
      type: Number,
      default: 40
    },
    height:{
      type: Number,
      default: 40
    },
     inline:{
      type: Boolean,
      default: false
    }
  },
};
</script>
