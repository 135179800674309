<template>
  <div>
    <draggable
      v-if="tappe.length > 0"
      tag="div"
      :list="tappe"
      class="accordion"
      handle=".handle"
      id="tappeAccordion"
      @end="onMoveCallback"
    >
      <div class="card" v-for="(element, idx) in tappe" :key="element.id">
        <div class="card-header" id="headingOne">
          <div class="row align-items-center">
            <div class="col-auto">
              <i class="fa fa-align-justify handle" style="cursor: move;"></i>
            </div>
            <div class="col mx-auto">
              {{ idx + 1 }}. {{ (element.contents[lang].title ?
              element.contents[lang].title + ", " : 'Tappa,' ) }} {{ element.location.address ?
              element.location.address + ", " : '' }} {{element.location.city.city }}
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-lg btn-link mx-2"
                data-toggle="collapse"
                :data-target="'#collapse-tappa' + idx"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-lg btn-link trash" @click="removeAt(idx)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          :id="'collapse-tappa' + idx"
          class="collapse collapsed"
          :aria-labelledby="'Tappa n°' + (idx + 1)"
          data-parent="#tappeAccordion"
        >
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-12">
                <textbox title="Titolo" v-model="element.contents[ lang ].title"/>
              </div>
              <div class="form-group col-md-12">
                <label>Descrizione</label>
                <rich-editor title="Descrizione" v-model="element.contents[ lang ].description"/>
              </div>
              <div class="form-group col-md-12">
                <label>Eventi in evidenza</label>
                <input
                  :id="'events-id-' + lang"
                  class="form-control"
                  type="text"
                  v-model="element.events[ lang ]"
                />
              </div>

              <div class="form-group col-md-12">
                <label>Distanza prossima tappa</label>
                <input
                    class="form-control"
                    type="text"
                    v-model="element.distanza[ lang ]"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label>Punto d'Interesse</label>
                <input
                  type="text"
                  required="true"
                  class="input form-control"
                  aria-required="true"
                  aria-invalid="false"
                  v-model="element.poi"
                />
              </div>


              <div class="form-group col-md-6">
                <label>Destinazioni</label>
                <destination-combo
                  required="true"
                  aria-required="true"
                  aria-invalid="false"
                  v-model="element.destination"
                  @comune="(entity) => { element.location.city = entity }"
                />
              </div>
            </div>

            <address-box v-model="element.location"></address-box>

            <card title="Foto" :collapse="false">
              <attachments-box :max="10" v-model="element.attachments"></attachments-box>
            </card>

            <card title="Video Link" :collapse="false">
              <div class="row" v-for="(video,index) in element.video[ lang ]" :key="video.id">
                <span class="col-auto mt-4 pt-2"> {{ index+1 }} </span>
                <textbox title="Titolo" v-model="video.title" class="col-md-5"/>
                <textbox title="Video" v-model="video.url" class="col-md-6"/>
              </div>
            </card>

            <card title="Pianificate il vostro viaggio" :collapse="false">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Immagine</th>
                    <th>Titolo</th>
                    <th>URL</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(planning,index) in element.planning[ lang ]" :key="planning.id">
                    <td>{{ planning.id }}</td>
                    <td v-if="planning.image" class="text-center" width="140px">
                      <div
                        :style="{ backgroundImage: 'url(' + planning.image.url_thumb +')' }"
                        class="attachment-thumbnail"
                      ></div>
                      <button
                        type="button"
                        class="copertina"
                        @click="show_dialog_image_in_component(planning,index)"
                      >Sostituisci la copertina
                      </button>
                    </td>
                    <td v-else>
                      <button
                        type="button"
                        class="copertina"
                        @click="show_dialog_image_in_component(planning,index)"
                      >Seleziona la copertina
                      </button>
                    </td>
                    <td>
                      <textbox v-model="planning.title"/>
                    </td>
                    <td>
                      <textbox v-model="planning.url"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </card>
            <card title="Ricette" :collapse="false">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Immagine</th>
                    <th>Titolo</th>
                    <th>URL</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(ricette,index) in element.ricette[ lang ]" :key="ricette.id">
                    <td>{{ ricette.id }}</td>
                    <td v-if="ricette.image" class="text-center" width="140px">>
                      <div
                        :style="{ backgroundImage: 'url(' + ricette.image.url_thumb +')' }"
                        class="attachment-thumbnail"
                      ></div>
                      <button
                        type="button"
                        class="copertina"
                        @click="show_dialog_image_in_component(ricette,index)"
                      >Sostituisci la copertina
                      </button>
                    </td>
                    <td v-else>
                      <button
                        type="button"
                        class="copertina"
                        @click="show_dialog_image_in_component(ricette,index)"
                      >Seleziona la copertina
                      </button>
                    </td>
                    <td>
                      <textbox v-model="ricette.title"/>
                    </td>
                    <td>
                      <textbox v-model="ricette.url"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </card>
            <card title="Podcast" :collapse="false">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Nome Podcast</th>
                    <th>URL</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="podcast in element.podcast[ lang ]" :key="podcast.id">
                    <td>{{ podcast.id }}</td>
                    <td>
                      <textbox v-model="podcast.title"/>
                    </td>
                    <td>
                      <textbox v-model="podcast.url"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </card>
          </div>
        </div>
      </div>
    </draggable>
    <div class="col-12 text-center">
      <button type="button" class="btn btn-primary" @click="addTappa()">Aggiungi Tappa</button>
    </div>
  </div>
</template>

<style scoped>
  .table > thead > tr > th {
    border-top: none;
  }

  .attachment-thumbnail {
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: cover;
    display: inline-block;
    display: block;
    margin: 2px auto;
  }

  button.copertina {
    background: none;
    border: none;
    color: #17a2b8;
    font-weight: bold;
    display: inline-block;
  }
</style>
<script>
  import draggable from "vuedraggable";

  import DestinationCombo from "./DestinationCombo";
  import AddressBox from "./AddressBox";
  import RichEditor from "./RichEditor";
  import Textbox from "./form/Textbox";
  import AttachmentsBox from "./AttachmentsBox";
  import Vue from "vue";
  import ImagePicker from "./richeditor/ImagePicker";

  export default {
    components: {
      RichEditor,
      draggable,
      AddressBox,
      DestinationCombo,
      Textbox,
      AttachmentsBox
    },
    props: {
      value: {
        type: Array,
        default: () => []
      },
      lang: {
        type: String,
        default: "it"
      }
    },
    data() {
      return {
        isLoading: true,
        tappe: []
      };
    },
    watch: {
      value: function (v2, v1) {
        this.updateTappe();
      }
    },
    methods: {

      template: function () {
        return {
          id: null,
          order: this.tappe.length + 1,
          location: {
            city: {
              id: "",
              city: "",
              province: "",
              province_short: "",
              region: "",
              zip_code: "",
              country: ""
            },
            address: "",
            number: "",
            lat: "",
            lng: ""
          },
          destination: {
            id: "",
            name: ""
          },
          poi: "",
          distanza: this.$contentTemplate({}),
          contents: this.$contentTemplate({
            description: "",
            title: "",
          }),
          events: this.$contentTemplate([]),
          video: this.$contentTemplate([
            {
              id: 1,
              url: "",
              title: ""
            },
            {
              id: 2,
              url: "",
              title: ""
            },
            {
              id: 3,
              url: "",
              title: ""
            },
            {
              id: 4,
              url: "",
              title: ""
            },
            {
              id: 5,
              url: "",
              title: ""
            }
          ]),
          planning: this.$contentTemplate([
            {
              id: 1,
              title: "",
              url: "",
              image: ""
            },
            {
              id: 2,
              title: "",
              url: "",
              image: ""
            },
            {
              id: 3,
              title: "",
              url: "",
              image: ""
            },
            {
              id: 4,
              title: "",
              url: "",
              image: ""
            },
            {
              id: 5,
              title: "",
              url: "",
              image: ""
            },
            {
              id: 6,
              title: "",
              url: "",
              image: ""
            }
          ]),
          ricette: this.$contentTemplate([
            {
              id: 1,
              title: "",
              url: "",
              image: ""
            },
            {
              id: 2,
              title: "",
              url: "",
              image: ""
            },
            {
              id: 3,
              title: "",
              url: "",
              image: ""
            }
          ]),
          podcast: this.$contentTemplate([
            {
              id: 1,
              title: "",
              url: ""
            },
            {
              id: 2,
              title: "",
              url: ""
            }
          ])
        };
      },
      addTappa: function (type) {
        this.tappe.push(this.template());
      },
      removeAt: function (idx) {
        if (confirm("Confermi la rimozione dell'elemento?")) {
          this.tappe.splice(idx, 1);
        }
      },
      onMoveCallback: function (evt, originalEvent) {
        for (var i in this.tappe) {
          this.tappe[i].order = Number(i) + 1;
        }
      },
      updateTappe: function () {
        this.$set(this, "tappe", this.value || []);
      },
      removeAt: function (idx) {
        if (confirm("Confermi la rimozione dell'elemento?")) {
          this.tappe.splice(idx, 1);
        }
      },
      show_dialog_image_in_component: function (file, index) {
        var ComponentClass = Vue.extend(ImagePicker);
        var instance = new ComponentClass({
          propsData: {max: 1}
        });
        instance.$mount();
        instance.$on("close", function () {
          instance.$destroy();
          this.$el.parentNode.removeChild(this.$el);
        });
        instance.$on("onimage", function (images) {
          this.$set(file, "image", images[0]);
          instance.$destroy();
          this.$el.parentNode.removeChild(this.$el);
        });
        this.$el.appendChild(instance.$el);
      }
    },

    mounted() {
      this.updateTappe();
      this.isLoading = false;
    }
  };
</script>

