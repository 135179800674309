<template  >
  <svg
  :class="[ 'icon' , open ? 'open' : 'close']"
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    
  >
    <path
      d="M8.03229 4.99989L0.560633 9.37874L0.504274 0.718667L8.03229 4.99989Z"
      fill="#66615b"
      :fill-opacity="open ? 0.95 : 0.6"
    />
  </svg>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.icon{
    transition-duration: 0.4s;
    transition-property: transform;

&.open {
    transform: rotate(90deg);    
}
 &   .close {
         transform: rotate(0deg);    
    }

}
    
</style>