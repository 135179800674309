<template>
  <div class="py-4 sticky" id="topBar">
    <div class="row">
      <div class="col-12 mb-4 mb-md-0 col-md mr-auto d-flex align-items-center">
        <button class="btn btn-link p-0 pr-2 mr-2" @click="niceback">
          <i class="fa fa-chevron-left"></i>
        </button>
        <h4 class="my-0 text-uppercase">{{ title }}</h4>
      </div>
      <lang-switcher
        v-if="hideLang !== true"
        class="col-auto mb-4 mb-md-0 "
        v-model="selected"
        :langs="$available_langs"
      >
        <template v-slot:badge="{ badge }">
            <span v-if="hasErrors && hasErrors[badge.code]"
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="hasErrors[badge.code] || ''"
                  class="badge badge-pill badge-danger"
            >!</span>
        </template>
      </lang-switcher>
      <div class="col-auto mb-4 mb-md-0">
        <a
          :href="preview"
          target="_blank"
          class="btn btn-success"
          v-if="preview !== ''"
          :disabled="loading==true"
        >ANTEPRIMA</a
        >
        <button
          v-if="action !== false"
          :class="['btn', 'btn-primary', !actionIsValid ? 'disabled' : '']"
          :disabled="!actionIsValid || loading==true"
          @click="$emit('onAction')"
        >{{ action }}<img v-if="loading" src="@/assets/img/spinner.gif" alt="loading" width="16" height="16" class="ml-2">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import LangSwitcher from "../../components/altrama/LangSwitcher";

  export default {
    components: {
      LangSwitcher
    },
    name: "top-bar",
    props: ["title", "preview", "action", "actionIsValid", "hideLang", "hasErrors", "loading"],
    methods: {
      debounce: _.debounce,
      niceback: function () {
        var numberOfEntries = window.history.length;
        if (numberOfEntries > 2) {
          this.$router.go(-1);
        } else {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath
          });
        }
      }
    },
    data() {
      return {
        selected: "it"
      };
    },
    watch: {
      selected: function (newVal, oldVal) {
        if (newVal !== oldVal) this.$emit("lang", newVal);
      }
    },
    mounted() {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
      var header = document.getElementById("topBar");
      if (header) {
        var sticky = header.offsetTop;

        window.onscroll = function () {
          myFunction();
        };

        // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function myFunction() {
          if (window.pageYOffset > sticky) {
            //var width = document.getElementsByClassName('content')[0].clientWidth - 30;
            //header.style.width = width + "px";
            header.classList.add("shadow");
          } else {
            header.classList.remove("shadow");
          }
        }
      }
    }
  };
</script>

<style>
  .sticky {
    position: fixed;
    top: 0px;
    z-index: 90;
    background-color: #f4f3ef;
    padding: 15px;
  }

  .expanded .sticky {
    left: 200px;
    width: calc(100% - 200px);
  }

  .collapsed .sticky {
    left: 53px;
    width: calc(100% - 53px);
  }

  @media (max-width: 991px) {

    .expanded .sticky {
      left: 0px;
      width: 100%;
    }

    .collapsed .sticky {
      left: 0px;
      width: 100%;
    }
  }

  .shadow {
    box-shadow: 2px 2px 4px 1px #6c757d54;
  }
</style>
