<template>
  <svg
    width="11px"
    height="18px"
    viewBox="0 0 11 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="DragHandle"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      fill-opacity="0.6"
    >
      <g id="drag-handle" fill="#66615b" fill-opacity="0.6">
        <circle id="Oval" cx="2" cy="2" r="2"></circle>
        <circle id="Oval" cx="2" cy="9" r="2"></circle>
        <circle id="Oval" cx="2" cy="16" r="2"></circle>
        <circle id="Oval" cx="9" cy="2" r="2"></circle>
        <circle id="Oval" cx="9" cy="9" r="2"></circle>
        <circle id="Oval" cx="9" cy="16" r="2"></circle>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
    data() { return { } }
};
</script>