<template>
  <div>
     <template v-for="l in langs">
        <button type="button"  :key="l.code" :class="['btn', btnClass, selected == l.code ?  'btn-primary' :'btn-outline-primary' ]" @click="selected = l.code"> {{ l.label }} <slot name="badge" v-bind:badge="l" > </slot> </button>
    </template>
  </div>
</template>
<script>
export default {
  name: 'lang-switcher',
  props: {
    value: {
      type: String,
      default: "it"
    },
    langs: {
      type: Array,
      default: [] ,
  },
  btnClass: {
    type: String,
    default: ""
  }
  },
  data() {
    return {
      selected: "it"
    };
  },
  watch: {
    selected: function(newVal, oldVal) {
      if(newVal !== oldVal)
        this.$emit("input", newVal);
    }
  }
};
</script>
<style>
</style>
