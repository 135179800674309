<template>
        <span>
            <span v-if="contents == ''" class="badge badge-dark">Non presente</span>
            <span v-if="contents == '-1'" class="badge badge-dark">Non presente</span>
            <span v-if="contents == '0'" class="badge badge-danger">Nascosto</span>
            <span v-if="contents == '1'" class="badge badge-success">Pubblicata</span>
            <span v-if="contents == '2'" class="badge badge-warning">Bozza</span>
            <span v-if="contents == '3'" class="badge badge-warning">Da Validare</span>
            <span v-if="contents == '4'" class="badge badge-secondary">Revisione</span>
            <span v-if="contents == '5'" class="badge badge-secondary">Da Rivalidare</span>
        </span>
</template>
<script>
    export default {
        name: "StatuseSpan",
        props: ['contents']
    }; // export
</script>
