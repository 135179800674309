<template>
    <div class="form-group">
        <label for="select-redazione">Redazione</label>
        <select
                id="select-redazione"
                v-model="options_data_selected"
                name="Redazione"
                v-validate="'required'"
                @change="notifySuperComponent()"
                class="custom-select"
        >
            <option v-for="item in options_data" :value="item.id" :key="item.id">{{item.nome}}</option>
        </select>

        <div class="text-danger" v-if="errors.has(('form-1.Redazione'))"

        >{{errors.first('form-1.Redazione')}}
        </div>

    </div>
</template>

<script>
    import Multiselect from "vue-multiselect";
    import axios from "axios";

    export default {

        data() {
            return {
                value: null,
                options_data: [],
                options_data_selected: "",
                myVarTimeout: "", //variabile per interrompere processo di timeout nelle richieste al server per ottenere le potenziali destinazioni, tag, categorie...
                isLoading: false
            };
        },

        props: {
            option_input: {
                type: Array,
                default: () => []
            },
            option_selected_input: {
                type: Number
            },

            type: {
                type: String,
                default: ""
            }
        },

        watch: {
            option_selected_input: function () {
                this.options_data_selected = this.option_selected_input;
                this.notifySuperComponent();
            }
        },

        inject: ["parentValidator"],

        methods: {
            notifySuperComponent: function () {
                this.$emit("updateRedazione", this.options_data_selected);
            },
            clearAll: function () {
                this.options_data_selected = [];
            },
            limitText(count) {
                return `e ${count} altre destinazioni`;
            },
        },

        created() {
            this.$validator = this.parentValidator;
            var self = this;

            axios({
                method: "get",
                url: this.$url + "operations/redazioni",
                headers: this.$config.axiosHeaders()
            })
                .then(function (response) {
                    self.options_data = response.data.data;
                })
                .catch(function (error) {
                    self.$processError(error);
                });

            //operazione necessaria in fase di edit per inizializzare i dati da restituire qualora non si facciano modifiche
            if (this.$route.params.id != "new") this.notifySuperComponent();
            this.notifySuperComponent();
        },
    }; // export
</script>
