<template>
  <div class="form-row">
    <div class="table-responsive col">
      <table class="table table-bordered">
        <tr>
          <th colspan="2">
            <input
              type="checkbox"
              :true-value="true"
              :false-value="false"
              v-model="always"
              class="mr-2"
              @change="updateAlways"
            />Sempre
          </th>
          <th v-for="(day,i) in weekDays" :key=" 'dayMask' + i">
            <input
              :disabled="always"
              type="checkbox"
              v-model="dayMask[i]"
              :true-value="1"
              :false-value="0"
              @change="updateDay"
            />
            {{ day }}
          </th>
        </tr>
      </table>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .table-responsive {
    border: none !important;
  }
</style>
<script>
  export default {
    components: {},
    data() {
      return {
        dayMask: [0, 0, 0, 0, 0, 0, 0],
        always: false,
        weekDays: ["lun", "mar", "mer", "gio", "ven", "sab", "dom"],
      };
    },
    props: ["value"],
    computed: {},
    watch: {
    value(v2, v1) {
      this.parse();
    }
  },
    methods: {
      updateAlways: function() {
        if( this.always ){
              this.dayMask = [1,1,1,1,1,1,1];
              this.updateDay();
        }
      },
      updateDay: function() {
        this.$emit("input", this.dayMask);
        this.$nextTick(() => {
          this.$emit("change", {});
        });
      },
      parse: function(){
        this.dayMask =[...(this.value || [])];
          if( this.dayMask.length != 7 ){
            this.dayMask = [0, 0, 0, 0, 0, 0, 0];
          }
      }
    },

    mounted() {
      this.parse();
    }
  };
</script>
