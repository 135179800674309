<template>
  <div class="form-group">
      <category-box :mainCategory='5' v-model="main" @change="sub=[]; notifySuperComponent();" >
        <template v-slot:options> <option value='-1' disabled> Seleziona una categoria </option> </template>
      </category-box>
      <category-combo :mainCategory='main.id' v-model="sub" @change="notifySuperComponent()" > </category-combo>
  </div>
</template>
<script>
import CategoryBox from "../../CategoryBox";
import CategoryCombo from "../../CategoryCombo";

export default {
components: {
  CategoryBox,
  CategoryCombo
},
  data() {
    return {
      main: {},
      sub: []
    };
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    value: function() {
      if(Array.isArray(this.value) && this.value.length==0){  
        //reinizializzo il componente se raggiungo la pagina dalla topbar 
        //nel caso in cui si creino due interessi consecutivi

        this.main=1;
        this.parseValue();
      }
    }
  },
  methods: {
    parseValue: function() {
      let self = this;
      self.sub = [];

      this.value.forEach(i => {
        if (i.parent == 5) {
          self.main = i;
        } else {
          self.sub.push(i);
        }
      });
    },
    notifySuperComponent: function() {
      this.$emit("input", [this.main].concat(this.sub));
    }
    },

    created() {
      this.parseValue();
    }

}; // export
</script>
