<!-- https://github.com/dangvanthanh/vue-ckeditor2 -->
<template>
  <div :class="[!!v && v.$error ? 'is-invalid' : '']">
    <ckeditor ref="editor" :editor="editor" v-model="editorData" :config="editorConfig" @ready="onReady"></ckeditor>
    <div ref="wordcount" id="container-for-word-count" class="text-right mt-2"></div>
    <div id="media-gallery"></div>
    <div class="invalid-feedback" :style="{ display: v.$error ? 'block' : 'none' }" v-if="!!v && !v.required">Campo
      richiesto</div>
  </div>
</template>
<style lang="scss">
.is-invalid {

  .ck.ck-toolbar,
  .ck.ck-editor__main>.ck-editor__editable {
    border: 1px solid red;
  }
}

.ck-word-count__characters,
.ck-word-count__words {
  display: inline;
  margin-right: 5px;
}

.ck-heading_heading3_with_line {
  &::before {
    content: "";
    display: block;
    height: 3px;
    background: #0e797b;
    width: 3px;
  }

  font-size: 17px !important;
  color: #030303;
  text-transform: uppercase;
}
</style>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';

// custom richeditor components
import MediaGallery from "../altrama/richeditor/mediagallery";
// import Gallery from "../altrama/richeditor/gallery";

export default {
  name: "rich-editor",

  components: {
    ckeditor: CKEditor.component
  },

  props: {
    // CONFIG
    useHeading: { type: Boolean, default: true },
    useFormatter: { type: Boolean, default: true },
    useList: { type: Boolean, default: true },
    useBlockquote: { type: Boolean, default: true },
    useScript: { type: Boolean, default: true },
    useLink: { type: Boolean, default: true },
    useAlignment: { type: Boolean, default: true },
    useIndent: { type: Boolean, default: true },
    useSpecialCharacters: { type: Boolean, default: true },
    useMediaGallery: { type: Boolean, default: true },
    useMediaEmbed: { type: Boolean, default: true },
    useTable: { type: Boolean, default: true },
    useHorizontalLine: { type: Boolean, default: true },
    useHtmlEmbed: { type: Boolean, default: true },
    useSourceEditing: { type: Boolean, default: true },
    useWordCount: { type: Boolean, default: true },
    // VALUE
    value: { type: String, default: '' },
    // VALIDATION
    v: { type: Object, default: null },
  },

  data() {
    const self = this;

    return {
      showCode: false,
      emit: true,
      editor: ClassicEditor,
      editorData: "",
      editorDataFormatted: "",
      editorConfig: {
        language: "it",
        plugins: [
          Paragraph,
          Essentials,
          Autoformat,
          RemoveFormat,
          PasteFromOffice,
          // Code,
          // MediaEmbedToolbar,

          // configure optionable plugins
          ...(self.configurePlugins())

        ],
        toolbar: {
          items: [
            "heading",
            "|",
            "removeFormat",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "|",
            "link",
            "|",
            "alignment",
            "indent",
            "outdent",
            "|",
            "subscript",
            "superscript",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "blockQuote",
            "|",
            'specialCharacters',
            "|",
            "mediaGallery",
            "mediaGalleryBlock",
            "mediaEmbed",
            "|",
            "insertTable",
            "|",
            "horizontalLine",
            "|",
            "htmlEmbed",
            "|",
            "undo",
            "redo",
            "|",
            "sourceediting",
          ],
          shouldGroupWhenFull: false,
          entities: false,
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading1' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading2' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading3' },
            {
              model: 'headingWithLine',
              view: {
                name: 'h3',
                classes: 'with-line'
              },
              title: 'Intestazione 3 con separatore',
              class: 'ck-heading_heading3_with_line',
              // It needs to be converted before the standard 'heading2'.
              converterPriority: 'high'
            }
          ]
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true
        },
        image: {
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative'
          ],
          styles: {
            options: ['inline', 'block', 'side']
          }
        },
        htmlEmbed: {
          showPreviews: true
        },
        mediaEmbed: {
          toolbar: [],
          elementName: 'o-embed'
        },
        mediaGallery: {
          parent: "media-gallery"
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties"
          ]
        },
        // extraPlugins: [MediaGallery],
        wordCount: {
          displayWords: true,
          displayCharacters: true
        }
      }
    };
  },

  watch: {

    value: function (newVal, oldVal) {
      this.emit = false;
      this.$set(this, "editorData", this.value);
    },

    editorData: function (newVal, oldVal) {
      // if (this.emit)
      this.$emit("input", newVal);
      this.emit = true;
    }

  },

  beforeCreate() {
    if (!document.getElementById("ckeditor5-lang")) {
      alert("ricordati di aggiungere <script id=\"ckeditor5-lang\" src=\"translations/it.js\"><\/script>  in index.html")
    }
  },

  created() {
    this.$set(this, "editorData", this.value);
  },

  methods: {

    configurePlugins: function () {
      const plugins = [];
      this.useHeading && (
        plugins.push(Heading)
      );
      this.useFormatter && (
        plugins.push(Bold),
        plugins.push(Italic),
        plugins.push(Underline),
        plugins.push(Strikethrough)
      );
      this.useList && (
        plugins.push(List)
      );
      this.useBlockquote && (
        plugins.push(BlockQuote)
      );
      this.useScript && (
        plugins.push(Subscript),
        plugins.push(Superscript)
      );
      this.useLink && (
        plugins.push(Link)
      );
      this.useAlignment && (
        plugins.push(Alignment)
      );
      this.useIndent && (
        plugins.push(Indent),
        plugins.push(IndentBlock)
      );
      this.useSpecialCharacters && (
        plugins.push(SpecialCharacters),
        plugins.push(SpecialCharactersEssentials)
      );
      this.useMediaGallery && (
        plugins.push(MediaGallery),
        plugins.push(Image),
        plugins.push(ImageCaption),
        plugins.push(ImageStyle),
        plugins.push(ImageToolbar),
        plugins.push(ImageUpload),
        plugins.push(ImageResize)
        // plugins.push(Gallery)
      );
      this.useMediaEmbed && (
        plugins.push(MediaEmbed)
      );
      this.useTable && (
        plugins.push(Table),
        plugins.push(TableToolbar),
        plugins.push(TableProperties),
        plugins.push(TableCellProperties)
      );
      this.useHorizontalLine && (
        plugins.push(HorizontalLine)
      );
      this.useHtmlEmbed && (
        plugins.push(HtmlEmbed)
      );
      this.useSourceEditing && (
        plugins.push(SourceEditing)
      );
      this.useWordCount && (
        plugins.push(WordCount)
      );
      return plugins;
    },

    updateCode: function (code) {
      var beautify_html = require('js-beautify').html;
      let fcode = beautify_html(code, {
        "indent_size": 4,
        "indent_char": " ",
        "indent_with_tabs": false,
        "editorconfig": false,
        "eol": "\n",
        "end_with_newline": false,
        "indent_level": 0,
        "preserve_newlines": true,
        "max_preserve_newlines": 10,
        "space_in_paren": false,
        "space_in_empty_paren": false,
        "jslint_happy": false,
        "space_after_anon_function": false,
        "space_after_named_function": false,
        "brace_style": "collapse",
        "unindent_chained_methods": false,
        "break_chained_methods": false,
        "keep_array_indentation": false,
        "unescape_strings": false,
        "wrap_line_length": 80,
        "e4x": false,
        "comma_first": false,
        "operator_position": "before-newline",
        "indent_empty_lines": false,
        "templating": ["auto"]
      });
      this.$set(this, "editorDataFormatted", fcode);
    },

    toggleCode: function () {
      this.showCode = !this.showCode;
      if (this.showCode == true) {
        // this.updateCode(this.value);
        this.updateCode(this.editorData);
      }
    },

    onReady: function (editor) {
      const wordCountPlugin = editor.plugins.get("WordCount");
      const wordCountWrapper = this.$refs.wordcount;
      wordCountWrapper.appendChild(wordCountPlugin.wordCountContainer);
    }

  }

};
</script>
